<template>
  <div>
    <v-tabs v-model="tab_details">
      <v-tab dense v-for="tab of tabs" :key="tab.id" :to="tab.link">
       {{ tab.name }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data(){
    return {
      value: 0,
      tab_details: 0,
      tabs:[
        { id: 1, name: 'INFORME MENSUAL', link: "/informe" },
        { id: 2, name: 'PRESUPUESTO', link: "/presupuesto" },
        { id: 3, name: 'SECTOR', link: "/sector" }
      ]
    }
  }
};
</script>